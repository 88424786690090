@import 'mixins';

$defaultFont: 'Open Sans', sans-serif;
$menuFont: 'Oswald', sans-serif;
$menuColor: #000;

/*primary*/
$buttonTextColor: #fff;
$buttonColor: #004d81;
$buttonBorderColor: #004d81;

/*default*/
$buttonDefaultTextColor: #333;
$buttonDefaultColor: #fff;
$buttonDefaultBorderColor: #ccc;

$searchColor: #c1c2c0;
$searchMobileColor: #434244;

/*checkout*/
$backgroundCheckout: #f5f5f5;

/*trash*/
$buttonTrashColor: #004d81;


/* fonts */
html, body, form, fieldset, table, tr, td, img {
    font-family: $defaultFont;
}

.navbar-default .navbar-nav > li > a,
//.Layout3Element .ad-buy-button,
//.Layout3Element .AttributeNewIcon,
//.ArticleWithBackground .heading-container *,
//.WebPubElement .buy-favorites,
//.WebPubElement .remove-favorites,
.SubMenuLink,
.menu-items-container .title,
.dropdown-menu .menu-show-all,
//.ProductInfo .heading-container h1,
//.ProductInfo .heading-container h2,
//.section-menu-item,
//.section-header span,
.ProductMenu .ProductMenuHeading,
.review-header span {
    font-family: $menuFont;
}

.free-shipping-counter {
    font-family: $defaultFont;
}

td.ProduktListPercent span,
.Layout3Element .YouSavePercentLabel,
.YouSavePercentProdImg {
    font-family: $menuFont;
}

/* end fonts */

.top-menu .TopMenu .TopMenuItem {
    /*Make sure hideOnSmall-items don't take up any space*/
    @include fromSizeSM() {
        padding: 0px 0px;

        .InfoMenu {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.top-menu .TopMenu .TopMenuItem .InfoMenu.hideOnSmall {
    display: none;

    @include fromSizeLG() {
        display: inherit;
    }
}

.publish-mode .top-menu .TopMenu .TopMenuItem .InfoMenu.hideOnSmall {
    display: inherit;
}


.HeaderLogo {
    background-image: url(Images/logo.png);
    width: 200px;

    @include fromSizeSM() {
        width: 251px;
        height: 57px;
    }
}

a.header-logo {
    @include fromSizeSM(){
        top: auto;
        margin-top: 21px;
    }
}

a.header-logo::after {
    font-size: 18px;
    color: black;
    font-weight: bold;
}

.btn-primary {
    color: $buttonTextColor;
    background-color: $buttonColor;
    border-color: $buttonBorderColor;

        &:hover {
            background-color: lighten($buttonColor, 5%);
            border-color: $buttonBorderColor;
    }
}

.btn-default {
    color: $buttonDefaultTextColor;
    background-color: $buttonDefaultColor;
    border-color: $buttonDefaultBorderColor;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: 0px;
}

.menu-container {
    padding-top: 43px;
    background: $buttonColor;

    @include fromSizeSM() {
        padding-top: 0;
        background: $menuColor;
    }
}
.SubMenuArea {
    min-height: 40px;
}

.SubMenuBox,
.navbar-default .navbar-nav > li > a {
    &:hover,
    &:focus,
    &:active {
        background: $buttonColor;
    }
}
.navbar-default .navbar-nav > li > a {
    font-size: 16px;
}

.menu-items-container .title,
.dropdown-menu .menu-show-all {
    font-size: 15px;
}

.MegaMenuNotPublished div:first-child .menu-show-all {
    @include fromSizeMD() {
        display: none;
    }
}

.navbar-default .navbar-nav > .open > a {
    background-color: $buttonColor;
    color: #ffffff;
}

 .navbar-nav>li:first-child > a {
    @include fromSizeMD(){
    padding-left: 15px;
    }
}

.navbar-nav>li:last-child > a {
    @include fromSizeMD(){
    padding-right: 15px;
    }
}

.InfoMenu {
    color: $menuColor;
}

.LogInButton {
    color: $menuColor;
}

.dropdown-menu a,
.dropdown-menu .title,
.dropdown-menu .menu-show-all {
    color: $menuColor;
}

.mcm .dropdown.mcm-fw .dropdown-menu {
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,2/10);
    border-top: none;
}

.footer-container {
    background: $buttonColor;
    color: white;
}

.copyright-container {
    background: $buttonColor;
    color: white;
    padding: 10px 0;
}

.copyright-container a {
    color: white;
}

a.cart-small-goto-checkout.btn.btn-primary {
    background: $buttonColor;
    border: none;
}

.free-shipping-counter {
    background: $buttonColor;
}

.ProductMenu a {
    color: $menuColor;
}

.ProductMenu .Level1Selected {
    background: $menuColor;
}

.ProductMenu .Level1Selected a {
    color: white;
}

.ProductMenu .Level2Selected {
    background-image: url(Images/menu-arrow.png);
    background-repeat: no-repeat;
    background-position: 30px center;

    @include toSizeMD {
        background-position-x: 6px;
    }
}
.ProductMenu .Level3Selected {
    background-image: url(Images/menu-arrow.png);
    background-repeat: no-repeat;
    background-position: 45px center;

    @include toSizeMD {
        background-position-x: 16px;
    }
}
.ProductMenu .Level4Selected {
    background-image: url(Images/menu-arrow.png);
    background-repeat: no-repeat;
    background-position: 45px center;

    @include toSizeMD {
        background-position-x: 26px;
    }
}
.mc-filter-left .attribute-left-box .has-filters,
.mc-filter-left.fullview.body-department .ProductMenu {
    background: white;
}

.section-menu-row {
    background: $menuColor;
    border-top: 1px solid $buttonColor;
    border-bottom: 1px solid $buttonColor;
}

.section-menu-item:hover {
    border-top: 2px solid $buttonColor;
}

.ProductInfo .section-header span::after {
    background: $buttonColor;
}

.BreadCrumb a:last-child {
    color: $buttonColor;
    font-weight: bold;
}

.Layout3Element .AddHeader1 {
    @include fromSizeSM() {
        font-size: 14px;
    }
}

.Layout3Element .AddHeader2 {
    @include fromSizeSM() {
        font-size: 12px;
    }
}

.Layout3Element .veil-price-container {
    display: none;
}

.Layout3Element .favorite-button,
.Layout3Element .ad-buy-button {
    background: transparent;

    @include fromSizeSM() {
        background: $buttonColor;
        color: white;
    }
}

.Layout3Element .fav-remove-button {
    background: transparent;

    @include fromSizeSM() {
        background: $buttonTrashColor;
        color: #fff;
        border-left: 1px solid;
    }
}

.Layout3Element .ad-buy-button {
    border-right-color: white;
    padding: 1px;
}

.buy-product,
.scoll-config-summary {
    font-weight: 300;
    font-family: $menuFont;
    background: $buttonColor;
    color: white;
    border-radius: 20px;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background: lighten($buttonColor, 10%);
        outline: none;
    }
}

.btn-favorite {
    font-weight: 300;
    font-family: $menuFont;
    background: $buttonDefaultColor;
    color: $buttonDefaultTextColor;
    border-radius: 20px;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background: lighten($buttonDefaultColor, 10%);
        outline: none;
    }
}

.remove-favorites-text {
    display:none;
}


.TableList .BuyButtonSmall,
.TableList .VariantShowMore {
    border-radius: 0;
    background: $buttonColor;
    font-family: $menuFont;
    font-size: 18px;

    &:hover {
        background: darken($buttonColor, 10%);
    }
}

.TableList .BuyButtonSmallDisabled {
    border-radius: 0;
    font-family: $menuFont;
    font-size: 18px;
}

.PrdListCellsPagingTop a,
.PrdListCellsPagingBottom a,
.FieldPaging a {
    &:hover {
        background: $buttonColor;
    }
}

.PrdListCellsPagingTop a.SelectedPagingIndex,
.PrdListCellsPagingBottom a.SelectedPagingIndex,
.FieldPaging a.SelectedPagingIndex {
    background: $buttonColor;
}

.less-qty,
.more-qty {
    border-radius: 50%;
    border-color: $menuColor;
    background: transparent;
    color: $menuColor;
    margin-top: 6px;

    &:hover {
        background: #eee;
    }
}

.less-qty {
    margin-right: 5px;
}

.more-qty {
    margin-left: 5px;
}

.QuantityTextBox {
    background: transparent;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    border: 1px solid $menuColor;
    color: $menuColor;
}

/* the checked style using the :checked pseudo class */
.radio-inline input[type="radio"]:checked + span:before {
    background-color: $buttonColor;
}

.list-type-button {
    color: $menuColor;
}

@include fromSizeSM() {
    a.cart-small-trigger.btn.btn-default,
    a.cart-small-goto-checkout.btn.btn-primary,
    .HeaderSearch table {
        border-radius: 2px;
    }

    .TextBoxSearchDisable, .TextBoxSearchEnable {
        width: 150px;
    }

    .HeaderSearch  {
        left: calc(50% - 90px);
    }
}

@include fromSizeMD() {
    .TextBoxSearchDisable, .TextBoxSearchEnable {
        width: 200px;
    }

    .HeaderSearch {
        left: calc(50% - 140px);
    }
}
@include fromSizeLG() {
    .TextBoxSearchDisable, .TextBoxSearchEnable {
        width: 250px;
    }

    .HeaderSearch {
        left: calc(50% - 150px);
    }
}

.search-btn {
    /*color: $searchMobileColor;*/
    @include fromSizeSM {
        color: $searchColor;
        font-size: 28px;
    }
}

.search-btn-text {
    display: none;
}

.checkout,
.checkout-left,
.checkout-right {
    background-color: $backgroundCheckout;
}

.body-checkout .PageContainerBorder {
    @include toSizeLG {
        background-color: $backgroundCheckout;
    }
}


.Layout3Element .YouSavePercentLabel,
td.ProduktListPercent span,
.YouSavePercentProdImg {
    background: $buttonColor;
    color: $buttonTextColor;
}

.attribute-left-box .LagerStatusContainer {
    display: none;
}

/* =Grunndata tabeller
--------------------------------------------------------------------------------------------------------------------*/
.PrdExtInfoTabell {
    width: 100%;
    padding: 0px;
    border-spacing: 2px;

    tr:first-child,
    .forstetabellrad {
        background: #005288;
        border: 1px solid #d8d6ce;
        color: #fff;
        font-weight: bold;
    }

    td {
        padding: 5px;
        border: 1px solid #ebeae5;
    }

    .liktabellrad {
        background-color: #ffffff;
    }

    .uliktabellrad {
        background-color: #ebeae5;
    }

    .liksistetabellrad {
        background-color: #ffffff;
    }

    .uliksistetabellrad {
        background-color: #ebeae5;
    }

    .celle0,
    .celle1,
    .celle2,
    .celle3,
    .celle4 {
        width: 0%;
    }
}

/* x antall celler */

/* Responsive youtube video */
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    /*padding-top: 30px;*/
    height: 0;
    overflow: hidden;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

p {
    margin: 0px;
}